<!--
  PACKAGE_NAME : src/pages/organize/grade
  FILE_NAME : position.vue
  AUTHOR : jhcho
  DATE : 24. 7. 5.
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid ref="positionGrid" :data-grid="dataGrid" @init-new-row="onInitNewRow" />
  </div>
</template>
<script>
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    data() {
      return {
        searchType: {
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        dataGrid: {
          refName: 'positionGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: false,
          dataSource: [],
          dataSourceDefaultSortColumn: '+gradeOrd',
          // width:'200',     // 주석처리시 100%
          height: '650', // 주석처리시 100%
          apiActionNm: {
            loading: true,
            update: 'POSITION_UPDATE',
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteItems();
                  },
                },
                location: 'before',
              },
            ],
          },
          customEvent: {
            initNewRow: true,
          },
          dragging: {
            sortColumn: 'positionOrd',
            allowReordering: true,
            dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '순서',
              dataField: 'positionOrd',
              height: 40,
              alignment: 'center',
              visible: false,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사이트*',
              dataField: 'siteId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
              requiredRule: {
                message: '사이트는 필수입니다.',
              },
            },

            {
              caption: '직책명*',
              dataField: 'positionNm',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              requiredRule: {
                message: '직책명은 필수입니다.',
              },
            },
            {
              caption: '사용 여부',
              dataField: 'viewFl',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      async deleteItems() {
        const deletedIds = this.$refs.positionGrid.selectedRowsData.map(d => {
          return { id: d.id };
        });

        if (!deletedIds?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (
          !(await this.$_Confirm(
            this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
          ))
        ) {
          return;
        }

        const payload = {
          actionname: 'POSITION_DELETE',
          data: deletedIds,
          loading: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
          this.$refs.positionGrid.refreshData();
        }
      },
      async selectDataList(sort = '+positionOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...this.searchType.paramsData, ...params };

            const payload = {
              actionname: 'POSITION_SELECT',
              data: { sort, params },
              loading: false,
            };
            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              this.$refs.positionGrid.totalCount = rtnData.totalCount;
              return rtnData;
            }
          },
        });
      },
      /**
       * @description 새로운 데이터의 개수를 반환
       * @param {Array} renderingData - 렌더링 데이터 배열
       * @returns {number} 새로운 데이터의 개수
       */
      getNewDataCount(renderingData) {
        return renderingData.filter(item => typeof item.key !== 'number')?.length || 0;
      },
      /**
       * @description 아이템의 최대 순서를 반환
       * @param {Array} items - 아이템 배열
       * @returns {number} 아이템의 최대 순서
       */
      getMaxItemOrd(items) {
        return items?.length ? Math.max(...items.map(item => item.positionOrd)) : 0;
      },
      onInitNewRow(e) {
        const renderingData = e.component.getVisibleRows();
        const newDataCount = this.getNewDataCount(renderingData);
        const items = this.$refs.positionGrid.getItems;
        const maxItemOrd = this.getMaxItemOrd(items);

        e.data.positionOrd = maxItemOrd + newDataCount + 1;
        e.data.viewFl = 'Y';
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {},
  };
</script>
<style scoped></style>